import * as actions from './actions'
import {
  addProduct,
  addProductsToUnifiedCart,
  getCartForDate,
  getUnifiedCartForDate,
  removeAllProducts,
  removeProduct,
  removeProductFromUnifiedCart
} from '../../api/sduiService'
import { deserializeUnifiedCart, mapProductProps } from './utils'
import * as orderActions from '../order/actions'

export const fetchCartForDate = date => dispatch => {
  dispatch(actions.fetchCartForDate({ date }))
  getCartForDate(date)
    .then(res => res.json())
    .then(({ data }) => {
      dispatch(
        actions.fetchCartForDateSucceed({
          date,
          cart: data?.products.map(mapProductProps),
          gifts: data?.gifts
        })
      )
    })
    .catch(() => {
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductToCart = (date, product, quantity) => dispatch => {
  dispatch(actions.addProductToCart())
  addProduct(date, product.inventoryId, quantity, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.addProductToCartSucceed({
          date,
          product: { ...product, ...productInCart }
        })
      )
      // to sync menu cart
      dispatch(orderActions.addProduct({ product, quantity }))
    })
    .catch(() => {
      dispatch(actions.addProductToCartFailed())
    })
}

export const removeProductFromCart = (date, product) => dispatch => {
  dispatch(actions.removeProductFromCart())
  removeProduct(date, product.inventoryId, product.id)
    .then(res => res.json())
    .then(res => {
      const productInCart = res?.items?.find(
        p => p.inventoryId === product.inventoryId
      )
      dispatch(
        actions.removeProductFromCartSucceed({
          date,
          product: productInCart,
          inventoryId: product.inventoryId
        })
      )
      // to sync menu cart
      dispatch(orderActions.removeProduct({ product, quantity: 1 }))
    })
    .catch(() => {
      dispatch(actions.removeProductFromCartFailed())
    })
}

export const clearCart = date => dispatch => {
  dispatch(actions.clearCart())
  removeAllProducts(date)
    .then(() => {
      dispatch(
        actions.clearCartSucceed({
          date
        })
      )
      // to sync menu cart
      dispatch(orderActions.clearOrder())
    })
    .catch(() => {
      dispatch(actions.clearCartFailed())
    })
}

export const fetchUnifiedCartForDate = date => dispatch => {
  dispatch(actions.fetchUnifiedCartForDate({ date }))
  getUnifiedCartForDate(date)
    .then(res => res.json())
    .then(data => {
      const unifiedCart = deserializeUnifiedCart(data)
      dispatch(
        actions.fetchUnifiedCartForDateSucceed({
          date,
          unifiedCart: unifiedCart
        })
      )
    })
    .catch(error => {
      console.log('error', error)
      dispatch(actions.fetchCartForDateFailed())
    })
}

export const addProductsToUnifiedCartForCartId = async (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.addProductToUnifiedCart())
  return addProductsToUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.addProductToUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.addProductToUnifiedCartFailed())
    })
}

export const removeProductsFromUnifiedCartForCartId = (
  cartId,
  products,
  dispatch
) => {
  dispatch(actions.addProductToUnifiedCart())
  return removeProductFromUnifiedCart(
    cartId,
    products.map(product => ({
      inventory_id: product.inventoryId || product.inventory_id,
      quantity: product.quantity
    }))
  )
    .then(res => {
      if (res.ok) return res
      throw new Error('something went wrong trying to delete product.')
    })
    .then(res => res.json())
    .then(cart => {
      const mappedCart = deserializeUnifiedCart(cart)
      dispatch(
        actions.removeProductFromUnifiedCartSucceed({
          date: cart.shipping_date,
          unifiedCart: mappedCart
        })
      )
      return mappedCart
    })
    .catch(() => {
      dispatch(actions.removeProductFromUnifiedCartFailed())
      throw new Error('something went wrong trying to delete product.')
    })
}
