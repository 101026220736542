import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { CuiButton } from 'front-lib'
import BackArrow from 'src/assets/icons/back_arrow.png'
import Header from 'src/components/Header/Header'
import './PreferencesPage.scss'
import {
  getPreferencesData,
  removeUserProfile,
  savePreferencesData
} from 'src/redux-api-bridge/preferences/effects'
import {
  getMealAvoids,
  getMealWishes,
  getPreferencesOptionsSelector,
  getPreferencesUserSelector,
  isErroredPreferences,
  isErroredSavingPreferences,
  isLoadingPreferences,
  isSavingPreferences,
  isUserW1ToW4
} from 'src/redux-api-bridge/preferences/selectors'
import { ProfileTabs } from './components/ProfileTabs/ProfileTabs'
import DeletePartner from 'src/modules/PersonalizeMeals/components/DeletePartner'
import AddPartner from 'src/modules/PersonalizeMeals/components/AddPartner'
import { AllergiesSelector } from './components/AllergiesSelector/AllergiesSelector'
import { DietSelector } from './components/DietSelector/DietSelector'
import { DropdownSelector } from './components/DropdownSelector/DropdownSelector'
import { ReactComponent as RemoveIcon } from 'src/assets/svg/delete-trash.svg'
import { toaster } from 'src/utils/toaster'
import { track } from 'src/shared/tracking'
import useConfirm from './components/SaveChangesModal/useConfirm'
import { isSectionCompleted, SAVE_MODAL_ACTIONS } from './utils'
import { RouterPrompt } from './components/SaveChangesModal/RouterPrompt'
import {
  getInvoicesInFlight,
  getInvoicesSucceed,
  getPastMeals
} from 'src/redux-api-bridge/selectors'
import { getLastInvoices } from 'src/redux-api-bridge'
import { MealPreferences } from './components/MealPreferences/MealPreferences'
import { useEmbedded } from 'src/embedded'
import { ProgressBar } from './components/ProgressBar/ProgressBar'
import { SECTIONS } from './components/ProgressBar/constants'
import { FocusableWrapper } from './components/FocusableWrapper/FocusableWrapper'
import AutopilotTab from 'src/pages/preferences/components/AutopilotTab/AutopilotTab'
import {
  AutopilotTabQuestion,
  AutopilotTabSection,
  AutopilotUserAttributes,
  StandardMealsQuestionResponses
} from 'src/types/preferences'
import { FF_AUTOPILOT_PANEL } from 'src/constants/feature-flags'
import { useFlag } from '@unleash/proxy-client-react'

export type AutopilotFormData = {
  household: string | null
  standardMeals: string | null
  flavorDiscovery: string | null
  premiumMeals: string[] | null
}

const getAutopilotFormFromUser = userPreferences => ({
  [AutopilotUserAttributes.HOUSEHOLD]:
    userPreferences[AutopilotUserAttributes.HOUSEHOLD],
  [AutopilotUserAttributes.STANDARD_MEALS]:
    userPreferences[AutopilotUserAttributes.STANDARD_MEALS],
  [AutopilotUserAttributes.FLAVOR_DISCOVERY]:
    userPreferences[AutopilotUserAttributes.FLAVOR_DISCOVERY],
  [AutopilotUserAttributes.PREMIUM_MEALS]:
    userPreferences[AutopilotUserAttributes.PREMIUM_MEALS]
})

const PreferencesPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const preferencesOptions = useSelector(getPreferencesOptionsSelector)
  const preferencesUser = useSelector(getPreferencesUserSelector)

  const [selectedProfileId, setSelectedProfileId] = useState()
  const [selectedProfileFormData, setSelectedProfileFormData] = useState<any>(
    {}
  )
  const [showDeletePartnerModal, setShowDeletePartnerModal] = useState(false)
  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false)

  const [mainProfileId, setMainProfileId] = useState()

  const isLoadingFromStore = useSelector(isLoadingPreferences)
  const [proteinsDisabled, setProteinsDisabled] = useState(false)

  const [hasAutopilotFormChanges, setHasAutopilotFormChanges] = useState(false)
  const [hasProfilesFormChanges, setHasProfilesFormChanges] = useState(false)
  const [creatingProfile, setCreatingProfile] = useState(false)

  const [autopilotFormData, setAutopilotFormData] = useState<AutopilotFormData>(
    {
      [AutopilotUserAttributes.HOUSEHOLD]: null,
      [AutopilotUserAttributes.STANDARD_MEALS]: null,
      [AutopilotUserAttributes.FLAVOR_DISCOVERY]: null,
      [AutopilotUserAttributes.PREMIUM_MEALS]: null
    }
  )

  const isSaving = useSelector(isSavingPreferences)
  const errorFetching = useSelector(isErroredPreferences)
  const errorSaving = useSelector(isErroredSavingPreferences)
  const { getConfirmationAction, ConfirmationPrompt } = useConfirm()

  const invoicesSucceed = useSelector(getInvoicesSucceed)
  const invoicesInFlight = useSelector(getInvoicesInFlight)
  const pastMeals = useSelector(getPastMeals)
  const mealWishes = useSelector(getMealWishes)
  const mealAvoids = useSelector(getMealAvoids)

  const [mealsToRate, setMealsToRate] = useState([])

  const [focusData, setFocusData] = useState<{
    section: string
    profileId: string
  }>()

  const { isAppEmbedded } = useEmbedded()

  const isAutopilotPanelEnabled = useFlag(FF_AUTOPILOT_PANEL)
  const isAutopilotTabDisabled = useSelector(isUserW1ToW4)

  useEffect(() => {
    dispatch(getPreferencesData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!invoicesInFlight && !invoicesSucceed) {
      dispatch(getLastInvoices())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesSucceed, invoicesInFlight])

  useEffect(() => {
    if (selectedProfileId && pastMeals) {
      const profileMealWishes = mealWishes
        ? mealWishes?.find(wishes => wishes.profileId === selectedProfileId)
            ?.mealWishes
        : []
      const profileMealAvoids = mealAvoids
        ? mealAvoids.find(avoids => avoids.profileId === selectedProfileId)
            ?.mealAvoids
        : []
      const mealsWishesAndAvoids = [
        ...(profileMealWishes || []).map(m => m.mealId),
        ...(profileMealAvoids || []).map(m => m.mealId)
      ]
      const unratedPastMeals = pastMeals.filter(
        meal => !mealsWishesAndAvoids.includes(Number(meal.id))
      )
      setMealsToRate(unratedPastMeals)
    }
  }, [pastMeals, mealWishes, mealAvoids, selectedProfileId])

  useEffect(() => {
    if (!!preferencesUser) {
      setHasProfilesFormChanges(false)
      setHasAutopilotFormChanges(false)
      setAutopilotFormData(getAutopilotFormFromUser(preferencesUser.autopilot))

      let initialProfile = selectedProfileId || location?.state?.profileId
      // if profile just created -> select the new one as default
      if (creatingProfile) {
        setCreatingProfile(false)
        initialProfile = preferencesUser?.profiles?.[1]?.id
      }
      if (initialProfile) {
        const profile = preferencesUser.profiles?.find(
          p => p.id === initialProfile
        )
        if (!!profile) {
          setSelectedProfileFormData({ ...profile })
          setSelectedProfileId(initialProfile)
        } else {
          // the selected profile doesn't exist
          setSelectedProfileFormData({ ...preferencesUser.profiles?.[0] })
          setSelectedProfileId(preferencesUser.profiles?.[0]?.id)
        }
      } else {
        setSelectedProfileId(preferencesUser.profiles?.[0]?.id)
        setSelectedProfileFormData({ ...preferencesUser.profiles?.[0] })
      }

      setMainProfileId(preferencesUser.profiles?.[0]?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferencesUser])

  useEffect(() => {
    errorSaving && toaster.error('Error saving preferences. Please try again.')
    errorFetching && toaster.error('Error loading page. Please try again.')
  }, [errorSaving, errorFetching])

  useEffect(() => {
    // to grey out related proteins if the selected diet has
    const userDiets = selectedProfileFormData?.diets?.map(d => d.id) || []
    setProteinsDisabled(
      preferencesOptions?.diets?.items.some(
        d => userDiets.includes(d.id) && d.relatedProteinIds?.length > 0
      )
    )
  }, [preferencesOptions?.diets, selectedProfileFormData?.diets])

  const toggleDeletePartnerModal = () => {
    setShowDeletePartnerModal(showDeletePartnerModal => !showDeletePartnerModal)
  }

  const savePreferences = () => {
    const profile = hasProfilesFormChanges
      ? {
          ...selectedProfileFormData,
          allergies: selectedProfileFormData.allergies.map(mapOnlyId),
          diets: selectedProfileFormData.diets.map(mapOnlyId),
          cuisines: selectedProfileFormData.cuisines.map(mapOnlyId),
          ingredients: selectedProfileFormData.ingredients.map(mapOnlyId),
          lifestyle: selectedProfileFormData.lifestyle.map(element => ({
            id: element.id,
            type: element.type
          })),
          proteins: selectedProfileFormData.proteins.map(mapOnlyId),
          noSelection: selectedProfileFormData.noSelection
        }
      : undefined
    const autopilot = hasAutopilotFormChanges ? autopilotFormData : undefined

    dispatch(savePreferencesData({ profile, autopilot }))
    trackPersonalizedMeals('Submit Form')
    window.scrollTo(0, 0)
  }

  const toggleAllergy = allergy => {
    clearFocus(SECTIONS.ALLERGENS)
    setHasProfilesFormChanges(true)
    let allergies
    if (!selectedProfileFormData?.allergies?.find(a => a.id === allergy.id)) {
      allergies = [...selectedProfileFormData.allergies, allergy]
    } else {
      allergies = selectedProfileFormData?.allergies?.filter(
        a => a.id !== allergy.id
      )
    }
    setSelectedProfileFormData({
      ...selectedProfileFormData,
      allergies,
      noSelection: {
        ...selectedProfileFormData.noSelection,
        allergies: false
      }
    })

    trackOnInputCompleted(
      preferencesOptions.allergies.title,
      allergies?.map(a => a.id),
      allergies?.map(
        a =>
          preferencesOptions?.allergies?.items?.find(
            allergy => allergy.id === a.id
          )?.label
      )
    )
  }

  const onChangeSelector = (selection, name) => {
    clearFocus(name)
    setHasProfilesFormChanges(true)
    setSelectedProfileFormData({
      ...selectedProfileFormData,
      [name]: selection,
      noSelection: {
        ...selectedProfileFormData.noSelection,
        [name]: false
      }
    })
    trackOnInputCompleted(
      preferencesOptions[name].title,
      selection?.map(s => s.id),
      selection?.map(s => s.label)
    )
  }

  const onNoPreferenceSelection = (name, value) => {
    clearFocus(name)
    setHasProfilesFormChanges(true)
    setSelectedProfileFormData({
      ...selectedProfileFormData,
      [name]: [],
      noSelection: {
        ...selectedProfileFormData.noSelection,
        [name]: value
      }
    })
    trackOnInputCompleted(
      preferencesOptions[name].title,
      [],
      value ? ['no-preferences'] : []
    )
  }

  const selectDiet = selected => {
    clearFocus(SECTIONS.DIET)
    setHasProfilesFormChanges(true)
    if (selectedProfileFormData.diets?.find(d => d.id === selected.id)) {
      setSelectedProfileFormData({
        ...selectedProfileFormData,
        diets: [],
        proteins: proteinsDisabled ? [] : selectedProfileFormData.proteins
      })
      setProteinsDisabled(false)
      trackOnInputCompleted(preferencesOptions.diets.title, null, null)
      return
    }
    if (selected.relatedProteinIds?.length > 0) {
      setSelectedProfileFormData({
        ...selectedProfileFormData,
        diets: [selected],
        proteins: selected.relatedProteinIds.map(p => ({ id: p })),
        noSelection: {
          ...selectedProfileFormData.noSelection,
          proteins: false
        }
      })
      setProteinsDisabled(true)
    } else {
      setSelectedProfileFormData({
        ...selectedProfileFormData,
        diets: [selected],
        proteins: proteinsDisabled ? [] : selectedProfileFormData.proteins
      })
      setProteinsDisabled(false)
    }
    trackOnInputCompleted(
      preferencesOptions.diets.title,
      selected?.id,
      selected?.label
    )
  }

  const setProfile = id => {
    setSelectedProfileId(id)
    setSelectedProfileFormData(
      preferencesUser?.profiles?.find(p => p.id === id)
    )
    setHasProfilesFormChanges(false)
    window.scrollTo(0, 0)
  }

  const checkStatusAndChangeProfile = async id => {
    if (hasProfilesFormChanges) {
      const action = await getConfirmationAction()
      if (action === SAVE_MODAL_ACTIONS.SAVE) {
        savePreferences()
      } else if (action === SAVE_MODAL_ACTIONS.CLOSE) {
        return
      }
    }
    setProfile(id)
  }

  const checkStatusAndAddToggleAddPartner = async () => {
    if (hasProfilesFormChanges && !showAddPartnerModal) {
      const action = await getConfirmationAction()
      if (action === SAVE_MODAL_ACTIONS.SAVE) {
        savePreferences()
      } else if (action === SAVE_MODAL_ACTIONS.CLOSE) {
        return
      }
    }
    setShowAddPartnerModal(showAddPartnerModal => !showAddPartnerModal)
  }

  const closeAddPartnerModal = () => {
    setShowAddPartnerModal(false)
  }

  const mapOnlyId = element => ({ id: element.id })

  const removeProfile = () => {
    dispatch(removeUserProfile({ profileId: selectedProfileId }))
    setShowDeletePartnerModal(false)
    trackPersonalizedMeals('Remove Partner')
  }

  const createPartnerProfile = name => {
    const payload = {
      id: 0,
      firstname: name,
      allergies: [],
      diets: [],
      cuisines: [],
      ingredients: [],
      lifestyle: [],
      proteins: []
    }
    setCreatingProfile(true)
    dispatch(savePreferencesData(payload))
    trackPersonalizedMeals('Add Partner')
  }

  const trackOnInputCompleted = (description, ids, names) => {
    track('Preference Input Completed', {
      preference_description: description,
      preference_selected_ids: ids,
      preference_selected_names: names
    })
  }

  const trackOnInputClicked = description => {
    track('Preference Input Clicked', {
      preference_description: description
    })
  }

  const trackPersonalizedMeals = action => {
    track('Personalized Meals', {
      category: 'Personalization',
      action
    })
  }

  const focusSection = (profileId, section) => {
    if (profileId !== selectedProfileId) {
      checkStatusAndChangeProfile(profileId)
    }
    setFocusData({ section, profileId })
  }

  const clearFocus = section => {
    if (section === focusData?.section) {
      setFocusData(undefined)
    }
  }

  const isFocused = section =>
    focusData?.section === section &&
    selectedProfileId === focusData?.profileId &&
    !isSectionCompleted(focusData?.section, selectedProfileFormData)

  const isLoading = isSaving || isLoadingFromStore || !preferencesOptions

  const findOptionsByUserAttributeAndMapToLabel = (
    userAttribute: AutopilotUserAttributes,
    selection: string[] | null,
    autopilotTabSections: AutopilotTabSection[]
  ): string[] | null => {
    let question: AutopilotTabQuestion | undefined = undefined

    autopilotTabSections.forEach(section => {
      if (!question) {
        question = section.items.find(
          item => item.userAttribute === userAttribute
        )
      }
    })

    return question && selection !== null
      ? (question as AutopilotTabQuestion).options
          .filter(option => selection.includes(option.id))
          .map(option => option.label)
      : null
  }

  const onUpdateAutopilotTab = (key, value) => {
    clearFocus(key)
    setHasAutopilotFormChanges(true)
    setAutopilotFormData({
      ...autopilotFormData,
      [AutopilotUserAttributes.FLAVOR_DISCOVERY]:
        key === AutopilotUserAttributes.STANDARD_MEALS &&
        value === StandardMealsQuestionResponses.PREVIOUS_ORDERS
          ? null
          : autopilotFormData[AutopilotUserAttributes.FLAVOR_DISCOVERY],
      [key]: value
    })
    trackOnInputCompleted(
      key,
      value,
      findOptionsByUserAttributeAndMapToLabel(
        key,
        value,
        preferencesOptions.sections
      )
    )
  }

  const progressBarInstance = (
    <ProgressBar
      profiles={preferencesUser?.profiles.map(p =>
        p.id === selectedProfileId ? selectedProfileFormData : p
      )}
      focusSection={focusSection}
    />
  )

  return (
    <>
      <Header />
      <DeletePartner
        isOpen={showDeletePartnerModal}
        onRequestClose={toggleDeletePartnerModal}
        removeProfile={removeProfile}
      />
      <AddPartner
        isOpen={showAddPartnerModal}
        onRequestClose={closeAddPartnerModal}
        createProfile={createPartnerProfile}
      />
      <RouterPrompt
        unsavedChanges={hasAutopilotFormChanges || hasProfilesFormChanges}
        onSave={savePreferences}
        onClose={() => {}}
        onProceed={() => {}}
      />
      <ConfirmationPrompt />
      <>
        <div className="preferences">
          <div className="preferences__container">
            <div className="preferences__header">
              {!isAppEmbedded && (
                <div
                  className="preferences__back"
                  role="button"
                  onClick={() => history.goBack()}>
                  <img src={BackArrow} alt="back" /> Back
                </div>
              )}
              <h1 className="preferences__title">Taste Profile</h1>
            </div>
            {isLoading ? (
              <>
                <div className="preferences-loading__form" />
                <div className="preferences-loading__form" />
              </>
            ) : (
              <>
                <div
                  className={classNames(
                    'preferences__progress-bar-wrapper hidden-mobile'
                  )}>
                  {progressBarInstance}
                </div>
                <div
                  className={classNames('preferences__body', {
                    'with-autopilot-panel': isAutopilotPanelEnabled
                  })}>
                  {isAutopilotPanelEnabled && (
                    <div className="preferences__body__autopilot">
                      <AutopilotTab
                        sections={preferencesOptions.sections}
                        autopilotFormData={autopilotFormData}
                        onUpdate={onUpdateAutopilotTab}
                        onQuestionClicked={trackOnInputClicked}
                        isSubmitting={isSaving}
                        isDisabled={isAutopilotTabDisabled}
                      />
                    </div>
                  )}
                  <div
                    className={classNames(
                      'preferences__progress-bar-wrapper only-mobile'
                    )}>
                    {progressBarInstance}
                  </div>
                  <div className="preferences__body__profiles">
                    <ProfileTabs
                      selectedProfileId={selectedProfileId}
                      setProfile={checkStatusAndChangeProfile}
                      addProfile={checkStatusAndAddToggleAddPartner}
                    />
                    <div className="preferences__row">
                      <FocusableWrapper
                        isFocused={isFocused(SECTIONS.ALLERGENS)}>
                        <AllergiesSelector
                          profileAllergies={selectedProfileFormData?.allergies}
                          toggleAllergy={toggleAllergy}
                          noAllergySelected={
                            selectedProfileFormData?.noSelection?.allergies
                          }
                          onNoPreferenceSelection={onNoPreferenceSelection}
                        />
                      </FocusableWrapper>
                      <FocusableWrapper isFocused={isFocused(SECTIONS.DIET)}>
                        <DietSelector
                          profileDiets={selectedProfileFormData?.diets}
                          selectDiet={selectDiet}
                        />
                      </FocusableWrapper>
                    </div>
                    <div className="preferences__dropdowns-row">
                      <FocusableWrapper
                        isFocused={isFocused(SECTIONS.LIFESTYLE)}>
                        <DropdownSelector
                          title={preferencesOptions?.lifestyle?.title}
                          subtitle={preferencesOptions?.lifestyle?.subtitle}
                          info={preferencesOptions?.lifestyle?.info}
                          items={preferencesOptions?.lifestyle?.items}
                          selected={selectedProfileFormData?.lifestyle}
                          isNoPreferenceSelected={
                            selectedProfileFormData?.noSelection?.lifestyle
                          }
                          disabled={false}
                          onChange={onChangeSelector}
                          onNoPreferenceSelection={onNoPreferenceSelection}
                          name={SECTIONS.LIFESTYLE}
                          withSearchInput={false}
                          onInputClicked={trackOnInputClicked}
                          showOptInOptions={false}
                          placeholder={'Select any that apply'}
                          withYesNoPlaceholder={false}
                          allowMultipleSelection={true}
                        />
                      </FocusableWrapper>
                      <FocusableWrapper
                        isFocused={isFocused(SECTIONS.PROTEINS)}>
                        <DropdownSelector
                          title={preferencesOptions?.proteins?.title}
                          subtitle={preferencesOptions?.proteins?.subtitle}
                          info={preferencesOptions?.proteins?.info}
                          items={preferencesOptions?.proteins?.items}
                          selected={selectedProfileFormData?.proteins}
                          isNoPreferenceSelected={
                            selectedProfileFormData?.noSelection?.proteins
                          }
                          disabled={proteinsDisabled}
                          onChange={onChangeSelector}
                          onNoPreferenceSelection={onNoPreferenceSelection}
                          name={SECTIONS.PROTEINS}
                          withSearchInput={false}
                          onInputClicked={trackOnInputClicked}
                          showOptInOptions={false}
                          placeholder={'Select any that apply'}
                          withYesNoPlaceholder={false}
                          allowMultipleSelection={true}
                        />
                      </FocusableWrapper>
                      <FocusableWrapper
                        isFocused={isFocused(SECTIONS.CUISINES)}>
                        <DropdownSelector
                          title={preferencesOptions?.cuisines?.title}
                          subtitle={preferencesOptions?.cuisines?.subtitle}
                          info={preferencesOptions?.cuisines?.info}
                          items={preferencesOptions?.cuisines?.items}
                          selected={selectedProfileFormData?.cuisines}
                          isNoPreferenceSelected={
                            selectedProfileFormData?.noSelection?.cuisines
                          }
                          disabled={false}
                          onChange={onChangeSelector}
                          onNoPreferenceSelection={onNoPreferenceSelection}
                          name={SECTIONS.CUISINES}
                          withSearchInput={false}
                          onInputClicked={trackOnInputClicked}
                          showOptInOptions={false}
                          placeholder={'Select any that apply'}
                          withYesNoPlaceholder={false}
                          allowMultipleSelection={true}
                        />
                      </FocusableWrapper>
                      <FocusableWrapper
                        isFocused={isFocused(SECTIONS.INGREDIENTS)}>
                        <DropdownSelector
                          title={preferencesOptions?.ingredients?.title}
                          subtitle={preferencesOptions?.ingredients?.subtitle}
                          info={preferencesOptions?.ingredients?.info}
                          items={preferencesOptions?.ingredients?.items}
                          selected={selectedProfileFormData?.ingredients}
                          isNoPreferenceSelected={
                            selectedProfileFormData?.noSelection?.ingredients
                          }
                          disabled={false}
                          onChange={onChangeSelector}
                          onNoPreferenceSelection={onNoPreferenceSelection}
                          name={SECTIONS.INGREDIENTS}
                          withSearchInput={true}
                          onInputClicked={trackOnInputClicked}
                          placeholder={'Select any that apply'}
                          withYesNoPlaceholder={false}
                          showOptInOptions={false}
                          allowMultipleSelection={true}
                        />
                      </FocusableWrapper>
                    </div>
                    {mainProfileId !== selectedProfileId && (
                      <div
                        className="preferences__delete-profile"
                        role="button"
                        onClick={toggleDeletePartnerModal}>
                        <RemoveIcon />
                        Delete profile
                      </div>
                    )}
                  </div>
                </div>
                {mealsToRate?.length > 0 && (
                  <MealPreferences
                    meals={mealsToRate}
                    profileId={selectedProfileId}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="preferences-footer">
          <CuiButton
            onClick={savePreferences}
            disabled={
              isLoading || !(hasProfilesFormChanges || hasAutopilotFormChanges)
            }
            size="large">
            Save Preferences
          </CuiButton>
        </div>
      </>
    </>
  )
}

export default PreferencesPage
