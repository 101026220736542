import { CuiButton } from 'front-lib'
import { useFlag } from '@unleash/proxy-client-react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

import {
  FF_PAUSE_INFORMATION_MODAL,
  FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT,
  FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
} from 'src/constants/feature-flags'
import { isIOSApp } from 'src/utils/utils'
import { FeedbackAfterPausingModal } from '../../FeedbackAfterPausingModal/FeedbackAfterPausingModal'
import { canCancelSubscriptionSelector } from 'src/pages/myPlan/components/CancelSubscription/selector'
import { useUnpause } from 'src/hooks/useUnpause'
import { CancelSubscription } from '../../CancelSubscription/CancelSubscription'
import { CancelSubscriptionWarning } from '../../CancelSubscription/CancelSubscriptionWarning'
import { useLastSkips } from 'src/hooks/useLastSkips'
import { getAllowPlanChange } from 'src/redux-api-bridge/selectors'

export function UnpausePlanSettings() {
  const [
    showFeedbackAfterPausingModal,
    setShowFeedbackAfterPausingModal
  ] = useState(false)
  const [planIsResumingSoon, setPlanIsResumingSoon] = useState(false)
  const { t } = useTranslation()
  const { unpausePlan, loading: loadingUnpause } = useUnpause()
  const canCancelSubscription = useSelector(canCancelSubscriptionSelector)
  const { firstPausedSkip, resumeData, loading: loadingSkips } = useLastSkips()
  const { allowPlanChange } = useSelector(getAllowPlanChange)

  const cancelSubscriptionIntoMyAccount = useFlag(
    FF_CANCEL_SUBSCRIPTION_INTO_MY_ACCOUNT
  )
  const ffDisableCancelSubscriptionButton = useFlag(
    FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
  )
  const enablePauseInformationModal = useFlag(FF_PAUSE_INFORMATION_MODAL)

  useEffect(() => {
    if (resumeData?.cutoff) {
      const cutoffResume = moment(resumeData.cutoff)
      const resumingSoon = moment().isSameOrAfter(cutoffResume)
      setPlanIsResumingSoon(resumingSoon)
    }
  }, [resumeData])

  async function handleUnpausePlan() {
    unpausePlan({ date: firstPausedSkip })
  }

  function handleFeedbackAfterPausingModal() {
    setShowFeedbackAfterPausingModal(!showFeedbackAfterPausingModal)
  }

  const isLoading =
    !firstPausedSkip || !resumeData.date || loadingUnpause || loadingSkips

  const disableButton =
    (ffDisableCancelSubscriptionButton && !canCancelSubscription.result) ||
    !allowPlanChange

  const titleKey = planIsResumingSoon
    ? 'pages.my_plan.plan_paused_resuming_soon'
    : 'pages.my_plan.plan_currently_paused'

  const showCancelSubscription = !isIOSApp() && !cancelSubscriptionIntoMyAccount

  return (
    <>
      {!isLoading &&
        enablePauseInformationModal &&
        showFeedbackAfterPausingModal && (
          <FeedbackAfterPausingModal
            onClose={handleFeedbackAfterPausingModal}
          />
        )}
      <div
        className={classnames(
          'cookunity__setting-section-container',
          isLoading && 'loading'
        )}>
        <div className="cookunity__setting-section-text-container">
          <span className="cookunity__plan-paused-section-title">
            {isLoading && <div>&nbsp;</div>}
            {!isLoading && t(titleKey)}
          </span>
          <ul className="cookunity__plan-paused-section-info">
            {isLoading ? (
              <>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </>
            ) : (
              <>
                <li>
                  <span className={'bold'}>
                    {t('pages.my_plan.plan_scheduled_resume', {
                      date: moment(resumeData.displayDate).format('MM/DD/YY')
                    })}
                  </span>{' '}
                  -{' '}
                  {t('pages.my_plan.plan_scheduled_resume_description', {
                    userTimezone: resumeData.timezone,
                    orderDeadline: resumeData.cutoff
                  })}
                </li>
                <li>{t('pages.my_plan.unpause_plan')}</li>
              </>
            )}
          </ul>
          {enablePauseInformationModal && (
            <span
              className="link check-calendar-pause margin-top"
              onClick={handleFeedbackAfterPausingModal}>
              {t('pages.my_plan.check_calendar_pause')}
            </span>
          )}
        </div>

        <div className="cookunity__setting-section-buttons-container paused">
          <CuiButton
            size="large"
            disabled={isLoading || planIsResumingSoon}
            className="cookunity__setting-section-buttons-unpause"
            onClick={handleUnpausePlan}>
            {t('pages.my_plan.unpause_plan_action')}
          </CuiButton>
          {showCancelSubscription && (
            <CancelSubscription pageName={'My Plan'} disabled={disableButton} />
          )}
        </div>
      </div>
      <div className={'cookunity__setting-section-cancellation-warning'}>
        {showCancelSubscription && <CancelSubscriptionWarning />}
      </div>
    </>
  )
}
