import { track } from 'src/shared/tracking'

export function useTrackMembershipModal() {
  function trackModalViewed(
    overlayName: string,
    overlayOptions: Array<string>,
    origin: string = ''
  ) {
    track('Overlay Viewed', {
      overlay_name: overlayName,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'custom',
      page_name: 'Orders',
      overlay_trigger: 'manual',
      overlay_options: overlayOptions,
      overlay_action: 'open',
      origin
    })
  }

  function trackModalClosed(overlayName: string) {
    track('Overlay Closed', {
      overlay_name: getClosedModalName(overlayName),
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'custom',
      page_name: 'Orders',
      overlay_trigger: 'manual'
    })
  }

  function trackOptionClicked(
    overlayName: string,
    overlayOptions: Array<string>,
    overlayAction: string,
    origin: string = ''
  ) {
    track('Overlay Clicked', {
      overlay_name: overlayName,
      overlay_class: 'modal',
      overlay_source: 'custom',
      overlay_type: 'custom',
      page_name: 'Orders',
      overlay_trigger: 'manual',
      overlay_options: overlayOptions,
      overlay_action: overlayAction,
      origin
    })
  }

  function getClosedModalName(word: string): string {
    const modalStep: { [key: string]: string } = {
      benefits: 'UnityPass Benefits',
      cancel: 'UnityPass Cancel',
      introduction: 'UnityPass Introduction',
      checkout: 'UnityPass Checkout',
      confirmation: 'UnityPass Welcome'
    }
    return modalStep[word.toLowerCase()] || 'Invalid word'
  }

  return {
    trackModalViewed,
    trackModalClosed,
    trackOptionClicked
  }
}
