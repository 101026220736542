import { DefaultFooter } from './DefaultFooter'
import { ActiveFooter } from './ActiveFooter'
import { RejoinFooter } from './RejoinFooter'
import { formatDate } from 'src/components/DeliveryCard/utils'

export function getModalHeader(membershipData) {
  if (membershipData?.startDate) {
    return {
      title: `THIS MONTH YOU SAVED: $${membershipData.accumulatedSavings || 0}`,
      subtitle: membershipData.active
        ? `Current benefits period: ${formatDate(
            membershipData.startDate
          )}-${formatDate(membershipData.endDate)}`
        : `Your Unity Pass has been canceled — current benefits will expire
    after ${formatDate(membershipData.endDate)}`
    }
  }
  return {
    title: 'Save at least $10 on',
    highlighted: 'every order!',
    subtitle:
      'Become a Unity Pass holder and start enjoying amazing benefits everytime you order. Here’s what you get:'
  }
}

export function hasIcon(benefit) {
  const benefitsWithIcon = ['deliveryFee', 'premiumMeals', 'addAndSave']
  return benefitsWithIcon.includes(benefit)
}

export function getUserStatus(membershipData) {
  if (membershipData?.active && membershipData?.stopDate) {
    return 'rejoin'
  }
  if (membershipData?.active && !membershipData?.stopDate) {
    return 'active'
  }
  return 'non-member'
}

export function getModalFooter(status) {
  switch (status) {
    case 'active':
      return ActiveFooter
    case 'rejoin':
      return RejoinFooter
    default:
      return DefaultFooter
  }
}

export function getModalOptionsForTracking(status) {
  switch (status) {
    case 'active':
      return ['Cancel Unity Pass']
    case 'rejoin':
      return ['Rejoin now']
    case 'non-member':
      return ['No thanks', 'Join now']
    default:
      return ['']
  }
}
