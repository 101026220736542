import { MealCardSlider } from 'src/components/MealCardSlider/MealCardSlider'
import { RegularMealCard } from 'src/componentsV2/MenuMealCard/RegularMealCard/RegularMealCard'
import { addMealAvoid, addMealWish } from 'src/redux-api-bridge'
import { useDispatch } from 'react-redux'
import {
  setMealAvoid,
  setMealWish
} from 'src/redux-api-bridge/preferences/effects'
import './MealPreferences.scss'

const CHEF_IMG_URL_SOURCE =
  process.env.REACT_APP_CHEF_IMG_URL_SOURCE + '/media/avatar/'

const MealPreferences = ({ meals, profileId }) => {
  const dispatch = useDispatch()

  const onLikeMeal = id => {
    dispatch(addMealWish({ meal: Number(id), profile: profileId }))
    dispatch(setMealWish({ meal: Number(id), profile: profileId }))
  }
  const onUnlikeMeal = id => {
    dispatch(addMealAvoid({ meal: Number(id), profile: profileId }))
    dispatch(setMealAvoid({ meal: Number(id), profile: profileId }))
  }

  return (
    <div className="preferences-meals">
      <div>
        <div className="preferences-meals__header-title">
          Help us understand you a little bit more
        </div>
        <div className="preferences-meals__header-subtitle">
          We will make sure to offer you the types of meals you like, and to
          avoid the ones you don´t.
        </div>
      </div>
      <MealCardSlider>
        {meals.map((meal, i) => (
          <div className="preferences-meals__meal" key={`${meal.id}-${i}`}>
            <RegularMealCard
              meal={{
                ...meal,
                chef: {
                  profileImageUrl: CHEF_IMG_URL_SOURCE + meal.bannerpic
                },
                full_path_meal_image: meal.image,
                magento_id: meal.id
              }}
              quantity={meal.quantity || 0}
              isEditable={false}
              isExtra={meal.extra}
              onLikeMeal={() => onLikeMeal(meal.id)}
              onUnLikeMeal={() => onUnlikeMeal(meal.id)}
              onMealClick={() => {}}
              onChefClick={() => {}}
              showFavoriteButtons={true}
            />
          </div>
        ))}
      </MealCardSlider>
    </div>
  )
}

export { MealPreferences }
