import classNames from 'classnames'
import { FormItemHeader } from 'src/pages/preferences/components/FormItemHeader/FormItemHeader'
import AutopilotQuestion from 'src/pages/preferences/components/AutopilotTab/components/AutopilotQuestion/AutopilotQuestion'
import {
  AutopilotTabSection,
  AutopilotUserAttributes,
  StandardMealsQuestionResponses
} from 'src/types/preferences'
import { AutopilotFormData } from 'src/pages/preferences/PreferencesPage'

import './AutopilotTab.scss'
import Card from 'src/pages/preferences/components/Card/Card'

type AutopilotTabProps = {
  sections: AutopilotTabSection[]
  autopilotFormData: AutopilotFormData
  onUpdate: Function
  onQuestionClicked: Function
  isSubmitting: boolean
  isDisabled: boolean
}

export const isAutopilotQuestionDisabled = (
  question,
  autopilotFormData
): boolean =>
  question.userAttribute === AutopilotUserAttributes.FLAVOR_DISCOVERY &&
  autopilotFormData[AutopilotUserAttributes.STANDARD_MEALS] ===
    StandardMealsQuestionResponses.PREVIOUS_ORDERS

const AutopilotTab = ({
  sections,
  autopilotFormData,
  onUpdate,
  onQuestionClicked,
  isSubmitting,
  isDisabled
}: AutopilotTabProps) => (
  <div className={classNames('autopilot-tab', { disabled: isDisabled })}>
    {isDisabled && (
      <>
        <div className="autopilot-tab__disabled-overlay" />
        <Card>
          <FormItemHeader
            title="Recommendation Set Up"
            subtitle="This feature will be available since <b>week 5</b>. Don’t worry, we will let you know when it is ready to use!"
          />
        </Card>
      </>
    )}
    {sections.map((section, index) => (
      <div key={`${index}-${section.title}`} className="autopilot-tab__section">
        <FormItemHeader
          title={section.title}
          subtitle={section.subtitle}
          info={section.info}
          className="section-title"
        />
        <div
          className={classNames(
            'autopilot-tab__section__questions-wrapper',
            String(section.layout).toLowerCase()
          )}>
          {section.items.map((question, index) => (
            <AutopilotQuestion
              key={`${index}-${question.userAttribute}`}
              type={question.type}
              title={question.title}
              subtitle={question.subtitle}
              info={question.info}
              question={question.question}
              clarification={question.clarification}
              allowMultipleSelection={question.allowMultipleSelection}
              showOptInOptions={question.showOptInOptions}
              options={question.options}
              userAttribute={question.userAttribute}
              autopilotFormData={autopilotFormData}
              onUpdate={onUpdate}
              onQuestionClicked={onQuestionClicked}
              isDisabled={
                isSubmitting ||
                isAutopilotQuestionDisabled(question, autopilotFormData)
              }
            />
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default AutopilotTab
