import { useEffect } from 'react'
import { CuiButton } from 'front-lib'

import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { Step, MembershipData } from 'src/types/membership'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'

import './MembershipIntroduction.scss'

interface Props {
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipIntroduction({ onContinue, membershipData }: Props) {
  const { trackModalViewed, trackOptionClicked } = useTrackMembershipModal()

  useEffect(() => {
    trackModalViewed('UnityPass Introduction', ['Discover Unity Pass benefits'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleContinue() {
    trackOptionClicked(
      'UnityPass Introduction',
      ['Discover Unity Pass benefits'],
      'Discover Unity Pass benefits'
    )
    onContinue(MEMBERSHIP_MODAL_STEPS.benefits)
  }
  return (
    <>
      <div className="unity-pass-banner" />
      <div className="introduction-container">
        <div className="title-container">
          <span className="title">Introducing Unity Pass:</span>
          <span className="subtitle">Save more, savor more!</span>
        </div>
        <div className="description-container">
          <span className="description">
            Start enjoying no delivery fee, exclusive discounts, and three{' '}
            premium meals per order at no extra cost for only $
            {membershipData?.membership?.cost}
            /month. It pays for itself in just two orders!
          </span>
        </div>
        <div className="introduction-buttons">
          <CuiButton onClick={handleContinue}>
            Discover Unity Pass benefits
          </CuiButton>
        </div>
      </div>
    </>
  )
}
