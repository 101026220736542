import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'

export function ActiveFooter({ onContinue }) {
  const { trackOptionClicked } = useTrackMembershipModal()
  function handleContinue() {
    trackOptionClicked(
      'UnityPass Benefits',
      ['Cancel Unity Pass'],
      'Cancel Unity Pass'
    )
    onContinue(MEMBERSHIP_MODAL_STEPS.cancel)
  }
  return (
    <div className="footer-container">
      <span className="cancel" onClick={handleContinue}>
        Cancel Unity Pass
      </span>
    </div>
  )
}
