import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { getMenu } from '../menu/selectors'
import { mealsAreEquals } from 'src/utils/utils'
import { formatMealData } from '../order/selectors'
import { getMealsCount } from '../order/utils'

export const getAllCartsSelector = state => pathOr([], ['cart', 'carts'], state)

export const cartByDateSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts ? carts[date] : null
  })

export const getAllGiftsSelector = state => pathOr({}, ['cart', 'gifts'], state)

export const getGiftsByDateSelector = date =>
  createSelector(getAllGiftsSelector, gifts => (gifts ? gifts[date] : null))

export const cartQuantityByDateSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts && carts[date]
      ? carts[date].reduce((total, item) => {
          return total + item.quantity
        }, 0)
      : null
  })

export const productsWithStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock > 0) ?? []
  })

export const productsWithoutStockInCartSelector = date =>
  createSelector(getAllCartsSelector, carts => {
    return carts?.[date]?.filter(p => p.stock <= 0) ?? []
  })

export const isCartOpenSelector = state =>
  pathOr(false, ['cart', 'isOpen'], state)

export const getCartErroredSelector = state =>
  pathOr('', ['cart', 'isError'], state)

export const getUnifiedCarts = state =>
  pathOr(null, ['cart', 'unifiedCarts'], state)

export const getUseUnifiedCart = state =>
  pathOr(null, ['cart', 'useUnifiedCart'], state)

export const getUnifiedCartsIsLoading = state =>
  pathOr(null, ['cart', 'isLoading'], state)

export const getUnifiedCartWithStock = state => date => {
  const carts = getUnifiedCarts(state)
  const menu = getMenu(state)
  const unifiedCart = carts?.[date]
  if (!carts || !menu || !unifiedCart) return []

  const allItems = [...(menu?.meals || []), ...(menu?.bundles || [])]

  const cart = unifiedCart.products.filter(item => {
    return (
      allItems.find(meal => (mealsAreEquals(item, meal) ? meal : null))?.stock >
      0
    )
  })
  const mappedCart = cart.map(item => {
    const foundMeal = allItems.find(meal => mealsAreEquals(item, meal))
    const formatedMeal = foundMeal ? formatMealData(foundMeal) : {}
    return {
      ...item,
      ...formatedMeal,
      qty: item.qty
    }
  })
  return mappedCart
}

export const getUnifiedCartProductsOutOfStock = state => date => {
  const carts = getUnifiedCarts(state)
  const menu = getMenu(state)
  const unifiedCart = carts?.[date]

  if (!carts || !menu || !unifiedCart) return []
  const allItems = [...(menu?.meals || []), ...(menu?.bundles || [])]

  return unifiedCart.products.filter(
    item => allItems.find(meal => mealsAreEquals(item, meal))?.stock <= 0
  )
}

export const getUnifiedCartMealsCountWithStock = state => date => {
  if (!date) return 0
  const productsWithStock = getUnifiedCartWithStock(state)(date)
  return getMealsCount(productsWithStock)
}
