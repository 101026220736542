import { Step } from 'src/types/membership'

export const COMPARE_PLANS_LOCALSTORAGE_KEY = 'tour-compare-plans-modal-viewed'
export const MONTHLY_MODAL_STEPS = {
  PAY_IN_ADVANCE: 'PAY_IN_ADVANCE',
  CONFIRM_YOUR_PLAN: 'CONFIRM_YOUR_PLAN',
  ACTIVATE: 'ACTIVATE',
  REACTIVATED: 'REACTIVATED',
  FAQ: 'FAQ'
}
export const MEMBERSHIP_MODAL_STEPS: Record<Step, Step> = {
  introduction: 'introduction',
  benefits: 'benefits',
  checkout: 'checkout',
  confirmation: 'confirmation',
  cancel: 'cancel',
  closed: 'closed'
}
