import * as actions from './actions'
import {
  fetchUserPreferencesData,
  fetchUserPreferencesView,
  removeUserProfileRequest,
  saveUserPreferencesData
} from 'src/api/sduiService'
import { getProfilesData } from '..'

export const getPreferencesData = (withView = true) => async dispatch => {
  dispatch(actions.fetchPreferencesData({ withView }))
  try {
    const user = await fetchUserPreferencesData()

    const preferencesData = {
      user
    }

    if (withView) {
      preferencesData.options = await fetchUserPreferencesView()
    }
    dispatch(actions.fetchPreferencesDataSucceed(preferencesData))
  } catch (error) {
    console.error(error)
    dispatch(actions.fetchPreferencesDataFailed())
  }
}

export const savePreferencesData = payload => dispatch => {
  dispatch(actions.savePreferencesData())
  saveUserPreferencesData(payload)
    .then(res => res.json())
    .then(() => {
      dispatch(actions.savePreferencesDataSucceed())
      dispatch(getPreferencesData(false))
      // to keep store updated in the subs-back format, todo: remove this api call and use new format everywhere
      dispatch(getProfilesData)
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.savePreferencesDataFailed())
    })
}

export const removeUserProfile = payload => dispatch => {
  dispatch(actions.removeUserProfile())
  removeUserProfileRequest(payload)
    .then(res => res.json())
    .then(() => {
      dispatch(actions.removeUserProfileSucceed())
      dispatch(getPreferencesData(false))
      // to keep store updated in the subs-back format, todo: remove this api call and use new format everywhere
      dispatch(getProfilesData)
    })
    .catch(err => {
      console.error(err)
      dispatch(actions.removeUserProfileFailed())
    })
}

export const setMealWish = payload => dispatch => {
  dispatch(actions.setMealWish(payload))
}

export const setMealAvoid = payload => dispatch => {
  dispatch(actions.setMealAvoid(payload))
}
