import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { CuiButton, CuiSpinner } from 'front-lib'

import { mapMealsForMembershipRefetch } from 'src/modules/MyPlan/components/OrderSummaryV2/utils'
import { getOrderWithStock } from 'src/redux-api-bridge/order/selectors'
import { useStartMembership } from 'src/api/Membership/startMembership'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { fetchOrderDetail } from 'src/redux-api-bridge/order/effects'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { MembershipData, Step } from 'src/types/membership'
import { toaster } from 'src/utils/toaster'

import './MembershipCheckout.scss'

interface Props {
  refetch: () => void
  onContinue: (step: Step) => void
  membershipData?: MembershipData
}

export function MembershipCheckout({
  membershipData,
  onContinue,
  refetch
}: Props) {
  const { startMembership, loading } = useStartMembership({
    onCompleted,
    onError
  })
  const location = useLocation()
  const dispatch = useDispatch()
  const orderWithStock = useSelector(getOrderWithStock)
  const origin = isOrderSummaryPath()
    ? 'Order checkout upselling banner'
    : 'User menu'
  const { trackOptionClicked, trackModalViewed } = useTrackMembershipModal()

  function isOrderSummaryPath() {
    const currentPath = location.pathname
    return currentPath.includes('/meals')
  }

  useEffect(() => {
    trackModalViewed('UnityPass Checkout', ['Confirm purchase'], origin)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onPurchaseMembership() {
    if (membershipData?.membership.id) {
      startMembership({ membershipId: membershipData.membership.id })
      trackOptionClicked(
        'UnityPass Checkout',
        ['Confirm purchase'],
        'Confirm purchase',
        origin
      )
    }
  }

  function onCompleted(response) {
    if (response.startMembership.success) {
      if (isOrderSummaryPath()) {
        const meals = mapMealsForMembershipRefetch(orderWithStock)
        dispatch(fetchOrderDetail(meals))
      }
      refetch()
      onContinue(MEMBERSHIP_MODAL_STEPS.confirmation)
    } else {
      onError()
    }
  }

  function onError() {
    toaster.error('Sorry, we encountered an error. Please try again.')
  }

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="checkout-container">
        {loading && (
          <div className="spinner-overlay">
            <CuiSpinner width="24px" />
          </div>
        )}
        <span className="title">Please review your Unity Pass purchase</span>
        <div className="summary-container">
          <span className="title">Order Summary</span>
          <div className="detail">
            <span>Unity Pass</span>
            <span>${membershipData?.membership.cost}</span>
          </div>
          <div className="summary-description">
            <span className="first">
              Benefits: no delivery fee, exclusive discounts, three premium
              meals per order at no extra fee.
            </span>
            <span className="second">
              This is a recurring monthly charge — cancel anytime.
            </span>
          </div>
          <hr className="dotted-line"></hr>
          <div className="summary-footer">
            <span>ORDER TOTAL</span>
            <span>${membershipData?.membership.cost}</span>
          </div>
        </div>
        <div className="terms">
          <span>
            By tapping the button, I agree to the Terms and a recurring monthly
            charge of ${membershipData?.membership.cost} plus tax until
            canceled. To avoid charges, cancel in your account before renewal.
          </span>
        </div>
        <div className="checkout-buttons">
          <CuiButton onClick={onPurchaseMembership}>Confirm purchase</CuiButton>
        </div>
      </div>
    </>
  )
}
