import { CuiButton } from 'front-lib'
import { MEMBERSHIP_MODAL_STEPS } from 'src/modules/Modals/constants'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'

export function DefaultFooter({ onContinue, onClose }) {
  const { trackOptionClicked } = useTrackMembershipModal()
  function handleContinue() {
    trackOptionClicked(
      'UnityPass Benefits',
      ['No thanks', 'Join now'],
      'Join now'
    )
    onContinue(MEMBERSHIP_MODAL_STEPS.checkout)
  }
  return (
    <div className="footer-container">
      <span className="footer-header">ALL THIS FOR $23.99/MO. —</span>
      <span className="footer-subheader">
        UNITY PASS PAYS FOR ITSELF IN JUST 2 ORDERS!
      </span>
      <div className="button-container">
        <CuiButton className="secondary-button" onClick={() => onClose()}>
          No thanks
        </CuiButton>
        <CuiButton className="primary-button" onClick={handleContinue}>
          Join now
        </CuiButton>
      </div>
    </div>
  )
}
