import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getMenu = state => pathOr([], ['menu', 'menu'], state)
export const getMenuDate = state => pathOr(null, ['menu', 'date'], state)
export const getCollections = state =>
  pathOr([], ['merchandiseSets', 'collections', 'merchandiseSets'], state)

export const isPrefetchLoading = state =>
  pathOr(false, ['menu', 'prefetchInFlight'], state)

export const menuMeals = state =>
  pathOr(false, ['menu', 'menu', 'meals'], state)

export const menuBundles = state =>
  pathOr(false, ['menu', 'menu', 'bundles'], state)

export const menuMealsAndBundles = createSelector(
  menuMeals,
  menuBundles,
  (meals, bundles) => {
    if (!meals || !bundles) return []
    return [...meals, ...bundles]
  }
)

export const isCrossSellingModalOpen = state =>
  pathOr(false, ['menu', 'isCrossSellingModalOpen'], state)

export const getCategories = state =>
  pathOr([], ['menu', 'menu', 'categories'], state)

export const getInitialScrollTo = state =>
  pathOr(null, ['menu', 'initialScrollTo'], state)

export const getFullMenuMealInitialPosition = state =>
  pathOr(null, ['menu', 'fullMenuMealInitialPosition'], state)

export const getMenuViewMode = state =>
  pathOr(null, ['menu', 'viewMode'], state)

export const getCrossSellingState = state =>
  pathOr(null, ['menu', 'crossSelling'], state)

export const getCrossSellingSeen = state =>
  pathOr(null, ['menu', 'crossSellingModalSeen'], state)

export const getMenuPromotions = state =>
  pathOr(null, ['menu', 'menu', 'promotions'], state)

export const getYouHaveTriedLoading = state =>
  pathOr(null, ['menu', 'alreadyRequestedMeals', 'inFlight'], state)
