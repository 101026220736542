import { useEffect, useState } from 'react'

import { ReactComponent as Bag } from '../../../../assets/svg/fullMenu/deliveredtoyourdoor.svg'
import { ReactComponent as Discounts } from '../../../../assets/svg/fullMenu/Add-and-Save.svg'
import { ReactComponent as Sides } from '../../../../assets/svg/fullMenu/sides.svg'
import {
  getModalFooter,
  getModalHeader,
  getModalOptionsForTracking,
  getUserStatus
} from './utils'
import { useTrackMembershipModal } from '../../useTrackMembershipModal'
import { Step, MembershipData } from 'src/types/membership'

import './MembershipBenefits.scss'

interface Props {
  onContinue: (step: Step) => void
  membershipData?: MembershipData
  onClose: () => void
  loading: boolean
}

export function MembershipBenefits({
  membershipData,
  onContinue,
  onClose,
  loading
}: Props) {
  const { trackModalViewed } = useTrackMembershipModal()
  const { title, subtitle, highlighted } = getModalHeader(
    membershipData?.activation
  )
  const [status, setStatus] = useState('')
  const Footer = getModalFooter(status)

  useEffect(() => {
    if (membershipData?.membership && !loading) {
      const status = getUserStatus(membershipData?.activation)
      const options = getModalOptionsForTracking(status)
      setStatus(status)
      trackModalViewed('UnityPass Benefits', options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      <div className="unity-pass-banner" />
      <div className="benefits-title-container">
        <span className="title">
          {title} <span className="every-order">{highlighted}</span>
        </span>
        <span className="subtitle">{subtitle}</span>
      </div>
      <div className="benefits-description-container">
        <span className="benefit-container-title">PASSHOLDER BENEFITS</span>
        <div className="benefit-container">
          <div className="image">
            <Bag />
          </div>
          <div className="benefit-details">
            <span className="benefit-title">No delivery fee</span>
            <span className="benefit-description">
              Enjoy unlimited free deliveries on all your orders.
            </span>
          </div>
        </div>
        <div className="benefit-container">
          <div className="image">
            <Discounts />
          </div>
          <div className="benefit-details">
            <span className="benefit-title">Exclusive discounts</span>
            <span className="benefit-description">
              Save up to $3.50 per meal with exclusive Add & Save discounts only
              for Unity Pass holders.
            </span>
          </div>
        </div>
        <div className="benefit-container">
          <div className="image">
            <Sides />
          </div>
          <div className="benefit-details">
            <span className="benefit-title">
              Premium meals at no extra cost
            </span>
            <span className="benefit-description">
              Experience our chefs’ finest creations — savor three premium meals
              per order at no additional cost.
            </span>
          </div>
        </div>
      </div>
      {!loading && (
        <Footer
          endDate={membershipData?.activation.endDate}
          onContinue={onContinue}
          onClose={onClose}
        />
      )}
    </>
  )
}
