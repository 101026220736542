import { ADDONS_CATEGORIES, MEALS_CATEGORY } from 'src/constants/menu'
import { mealsAreEquals } from 'src/utils/utils'

export const mapProductProps = product => ({
  ...product.properties,
  title: product.properties.name,
  premium_special: product.properties.isPremiumMeal,
  imageUrl: product.properties.image,
  qty: product.properties.cart.quantity,
  quantity: product.properties.cart.quantity,
  categoryId: getCategoryId(product.properties),
  meals: product.properties.items,
  premium_fee: product.properties.premiumFee,
  chef_firstname:
    product.properties.chef?.firstname || product.properties.cartChef?.name,
  chef_lastname: product.properties.chef?.lastname,
  full_path_chef_image: product.properties.chef?.image,
  short_description: product.properties.description,
  specifications_detail: product.properties.specifications
})

const getCategoryId = properties => {
  if (properties.containsMeal) return MEALS_CATEGORY
  return properties.isExtra ? ADDONS_CATEGORIES[0] : MEALS_CATEGORY
}

export const getCartFromMenuCategories = (categories, cartItems) =>
  [].concat(
    ...categories.map(c =>
      c.meals
        .filter(product =>
          cartItems.find(item => mealsAreEquals(item, product))
        )
        .map(product => {
          const itemIndex = cartItems.findIndex(item =>
            mealsAreEquals(item, product)
          )

          return {
            ...cartItems[itemIndex],
            ...product,
            qty: cartItems[itemIndex].qty,
            quantity: cartItems[itemIndex].qty,
            stock: product.stock,
            specifications_detail: product.specifications_detail || []
          }
        })
    )
  )

export const deserializeUnifiedCart = cart => ({
  cartId: cart.cart_id,
  shippingDate: cart.shipping_date,
  storeId: cart.store_id,
  products: cart.products.map(p => ({
    inventoryId: p.inventory_id,
    quantity: p.quantity,
    qty: p.quantity
  }))
})
