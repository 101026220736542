import { useSelector } from 'react-redux'
import { useFlag } from '@unleash/proxy-client-react'

import { FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON } from 'src/constants/feature-flags'
import { useIsMobile } from 'src/hooks/useIsMobile'
import { getAllowPlanChange } from 'src/redux-api-bridge/selectors'
import { canCancelSubscriptionSelector } from 'src/pages/myPlan/components/CancelSubscription/selector'

export function CancelSubscriptionWarning() {
  const isMobile = useIsMobile()
  const ffDisableCancelSubscriptionButton = useFlag(
    FF_DISABLE_CANCEL_SUBSCRIPTION_BUTTON
  )
  const allowPlanChange = useSelector(getAllowPlanChange)
  const canCancelSubscription = useSelector(canCancelSubscriptionSelector)

  const showWarningDisableCancelButton =
    ffDisableCancelSubscriptionButton &&
    !canCancelSubscription.result &&
    canCancelSubscription.completed

  if (!allowPlanChange || showWarningDisableCancelButton) {
    return isMobile ? (
      <p className="action-account">
        Learn more about canceling your subscription in{' '}
        <a href="https://support.cookunity.com">our FAQs</a>.
      </p>
    ) : (
      <p className="action-account">
        You will have the option to cancel your plan after your first delivery.
        Have questions? Chat with us using the Help widget at the bottom left of
        your screen.
      </p>
    )
  }

  return null
}
